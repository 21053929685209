@import '~antd/dist/antd.css';
@import 'antd/index.css';
@import 'react-masonry-css.css';

html {
  font-size: 16px;
}

*::-webkit-scrollbar {
  opacity: 0.6;
  height: 0.5em;
  width: 0.5em;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

body {
  font-size: inherit;
}

body,
html,
#root {
  height: 100%;
}

@media print {
  body {
    background-color: white !important;
  }
}

#footer {
  flex-shrink: 0;
}

body {
  font-display: swap !important;
  line-height: initial !important;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
}

@media print {
  body {
    padding: 0 0.5em;
  }
}

img {
  max-width: 100%;
}

.cp-c-row {
  display: flex;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0 !important;
}

p {
  margin: 0 0 1em;
  line-height: 1.4em;
}

a {
  text-decoration: none;
}

b {
  font-weight: bold;
}


small {
  font-size: 80%;
}

body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

  input[type='text'].ant-input,
  input[type='number'].ant-input {
    line-height: normal;
    /* CSS Hack only for IE.*/
  }

  input[type='text'].ant-input {
    text-align: left;
  }
}

.notification-container.notification-container:not(.notification-container-empty) {
  padding-bottom: 1em;
}

.notification.notification {
  background: rgba(255, 255, 255, 0.95);
  color: #5a5a5a;
  border-radius: 5px;
  opacity: 1;
}

.notification-error.notification-error {
  border: 1px solid #bd362f;
}

.notification-error.notification-error:before {
  color: #bd362f;
}

.notification-success.notification-success {
  border: 1px solid #aaca38;
}

.notification-success.notification-success:before {
  color: #aaca38;
}

/* antd overrides */

.ant-tabs {
  font-size: inherit !important;
}

.ant-tabs-tab {
  font-size: inherit !important;
}

.ant-picker {
  font-size: inherit !important;
  max-height: 50px;
}

.ant-form {
  font-size: inherit !important;
}

.ant-dropdown {
  font-size: inherit !important;
}

.ant-radio {
  font-size: inherit !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font-size: inherit !important;
}

.ant-form-label {
  font-size: inherit !important;
}

.ant-form-item {
  font-size: inherit !important;
}

.ant-form-item label {
  font-size: 0.9em;
}

.ant-form-item.ant-form-item label {
  color: #5a5a5a;
}

.ant-spin-dot {
  font-size: inherit !important;
}

.ant-btn-primary.ant-btn-primary {
  font-weight: 600;
}

.ant-radio-group {
  font-size: inherit !important;
}

.ant-checkbox-wrapper {
  font-size: inherit !important;
}

.ant-upload-list {
  font-size: inherit !important;
}

.ant-upload-list-item {
  font-size: inherit !important;
}

.ant-picker-dropdown {
  font-size: inherit !important;
}

.ant-switch {
  font-size: inherit !important;
}

.ant-input-affix-wrapper {
  font-size: inherit !important;
}

.ant-form-item-label {
  line-height: 1em !important;
}

.bold {
  font-weight: 600;
}

.hidden {
  visibility: hidden;
}

.ant-form-item-label>label:not(ant-form-item-required)::after {
  display: none;
}

.ant-form-item-required::before {
  display: none !important;
}

/* antd autocomplete */

.ant-select-dropdown {
  font-size: 0.875em;
}

.autocomplete-category-search-dropdown.ant-select-dropdown .ant-select-dropdown-menu {
  max-height: 400px;
}

.autocomplete-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #5a5a5a;
  font-weight: 500;
}

.autocomplete-category-search-dropdown .item-count {
  position: absolute;
  color: #999;
  right: 1em;
}

.autocomplete-category-search-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.autocomplete-category-search-dropdown .ant-select-dropdown-menu-item-active:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #eee;
}

.autocomplete-category-search-dropdown .show-all {
  color: #5a5a5a;
  border-top: 1px solid #eee;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

/* react modal styles */
@keyframes slideOpenModal {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideCloseModal {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes overlayLaunchModal {

  0%,
  33% {
    opacity: 1;
  }

  33%,
  100% {
    opacity: 1;
  }
}

@keyframes overlayDropModal {
  67% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes launchModal {

  0%,
  33% {
    opacity: 0.1;
    transform: translate(-51%, 175%);
  }

  100% {
    opacity: 1;
    transform: translate(-51%, -51%);
  }
}

@keyframes dropModal {
  0% {
    opacity: 1;
    transform: translate(-51%, -51%);
  }

  100% {
    opacity: 0.1;
    transform: translate(-51%, 150%);
  }
}

.ReactModal__Content {
  transform: translateX(100%);
  transition: transform 550ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translateX(0);
}

.ReactModal__Content--before-close {
  transform: translateX(100%);
}

.react-loading-skeleton {
  animation: none !important;
}
