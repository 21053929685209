.ant-notification {
  /* I think that it is unnecessary, but kept to save same styling */
  width: 540px;
  margin-right: 0.5em;
  margin-bottom: 0;
}
.ant-notification .ant-notification-notice {
  --border-color: gray;
  border: 1px solid var(--border-color);
  border-left-width: 5px;
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  padding: 0.5em 1em 0.5em 0.5em;
  margin-bottom: 0.5em;
}

/* + Colors */
.ant-notification .ant-notification-notice.type-error {
  --border-color: var(--notification-color-error);
}
.ant-notification .ant-notification-notice.type-success {
  --border-color: var(--notification-color-success);
}
.ant-notification .ant-notification-notice.type-info {
  --border-color: var(--notification-color-info);
}
.ant-notification .ant-notification-notice.type-warning {
  --border-color: var(--notification-color-warning);
}
/* - Colors */

.ant-notification-notice.ant-notification-notice-closable {
  padding-right: 3em;
}

.ant-notification-notice .ant-notification-notice-close {
  top: calc(50% - 0.75em);
  right: 1em;
}
.ant-notification-notice .ant-notification-notice-close .ant-notification-close-icon {
  color: var(--color-black);
  font-size: 1.3em;
}

.ant-notification-notice-with-icon {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  grid-template-areas:
    'icon message'
    'icon description';
}
.ant-notification-notice-with-icon .ant-notification-notice-icon {
  grid-area: icon;
  position: unset;
  font-size: 2em;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  grid-area: message;
  margin-bottom: unset;
  margin-left: 0.5em;
  font-size: unset;
  align-self: end;
  color: var(--color-black);
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  grid-area: description;
  align-self: start;
  margin-left: 0.5em;
  color: var(--color-dustyGray);
  white-space: pre-line;
}
